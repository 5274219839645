
import React, { useState } from "react";
import "./styles.css";
import ReactDOM from "react-dom";
import CloseIcon from "@material-ui/icons/Close";
import Dropdown from "../../Dropdown/index";
import MultiSelect from "../../MultiSelect/index";
import Gateways from "../../../gateways/api";
import { useToasts } from "react-toast-notifications";
import { findTemplateByName, findAllTemplateIsNotCitas } from "../../../services/firebase/plantilla/index";
import {
  ModalBody,
  ModalContainer,
  ModalHeaderContainer,
  ModalHeaderTitle,
  ModalButton,
  ModalChildrenContainer,
  ContainerBody,
  Column,
} from "./style";
import { fetchPatientByBusinessId } from "../../../services/firebase/pacientes/index";
import { getBusinessByPhone, getEmpresaById } from "../../../services/firebase/empresas/index";
const componentTypes = { HEADER: "header", BODY: "body", FOOTER: "footer" };

const getAllTemplate = async (businessID) => {
  const [templateMeta, templateDB] = await Promise.all([
    Gateways.MetaWhatsAppApi.getAllTemplatesByBusinessId(businessID),
    findAllTemplateIsNotCitas()
  ]);
  const templateDBNames = new Set(templateDB.map(template => template.nombre));
  const filteredTemplateMeta = templateMeta.data.filter(template => templateDBNames.has(template.name));
  return filteredTemplateMeta;

};

const transformParameters = (parametros) => {
  const createLabel = (field) => {
    switch (field) {
      case "{{_nombres_}}":
        return "+ NOMBRE";
      case "{{_apellidos_}}":
        return "+ APELLIDOS";
      case "{{_empresa_}}":
        return "+ EMPRESA";
      case "{{_cita_}}":
        return "+ CITA HORA";
      case "{{_welcome_image_}}":
        return "+ IMAGEN DE BIENVENIDA";
      case "{{_googleBusiness_}}":
        return "+ LINK GOOGLE BUSINESS";
      default:
        return "+ UNKNOWN";
    }
  };
  const transformSection = (section) => {
    return section.map(({ field, type }) => ({
      label: createLabel(field),
      value: { type, field },
    }));
  };
  const { body = [], header = [], footer = [] } = parametros;
  return {
    body: transformSection(body),
    header: transformSection(header),
    footer: transformSection(footer),
  };
};


export const Modal = (props) => {
  const { addToast } = useToasts();
  const [sendSmsPersonalized, setSendSmsPersonalized] = useState(true);
  const [listTemplates, setlistTemplates] = useState([]);
  const [variables, setVariables] = useState({});
  const [message, setMessage] = useState({});
  const [template, setTemplates] = useState({
    header: { text: "", example: { body_text: [] } },
    body: { text: "", example: { body_text: [] } },
    footer: { text: "", example: { body_text: [] } },
  });
  const [inputValues, setInputValues] = useState({});
  const [listClients, setListClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [showMultiSelect, setShowMultiSelect] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMessage({ ...message, [name]: value });
  }

  const handleSetVariable = (index, variable, field) => {
    setInputValues((prevValues) => {
      const newValues = { ...prevValues };
      if (!newValues[field]) {
        newValues[field] = [];
      }
      newValues[field][index] = variable;
      return newValues;
    });
  };


  if (listTemplates.length == 0) {
    const listTemplates = getAllTemplate(props.businessID);
    listTemplates.then((item) => {
      setlistTemplates(item);
    });
  }

  React.useEffect(() => {
    if (listClients.length == 0) {
      const phoneNumber = props.phone.replace('+', '').replace(/\s+/g, '');
      getBusinessByPhone(phoneNumber).then(item => {
        fetchPatientByBusinessId(item.id)
          .then((patients) => {
            const clientsWithLabels = patients.map((patient) => ({
              ...patient,
              label: `${patient.nombres} ${patient.apellidos}`,
              value: `${patient.nombres} ${patient.apellidos}`
            }));

            setListClients(clientsWithLabels);
          })
      });

    }
  }, []);

  const handleSendPersonalized = () => {
    setShowMultiSelect(true);
    setSendSmsPersonalized(true);
  };

  const handleSendTemplate = async (item) => {
    let { parametros } = await findTemplateByName(item.name);
    parametros = parametros && Object.keys(parametros).length ? {
      header: parametros.header || [],
      body: parametros.body || []
    } : { header: [], body: [], footer: [] };

    setInputValues({ ...parametros })

    const transformedParams = transformParameters(parametros);

    setVariables({ ...transformedParams });
    const { components } = item;
    const componentVariables = {};

    for (const [type, variable] of Object.entries(componentTypes)) {
      const foundComponent = components.find(
        (element) => element.type === type
      );
      if (foundComponent) componentVariables[variable] = foundComponent;
      else componentVariables[variable] = { text: "" };
    }

    const { header, body, footer } = componentVariables;
    setTemplates({ ...item, header, body, footer });
    setSendSmsPersonalized(false);
    setShowMultiSelect(false);
  };

  const handleSelectionChange = (selectedValues) => {
    setSelectedClient(selectedValues);
  };

  const sendMessageWhatsapp = async () => {
    try {

      const patients = await mapperClientData(showMultiSelect ? selectedClient : listClients);
      if (!patients.length) {
        return addToast("Seleccione un Paciente", { appearance: "error" });
      }

      const isPersonalized = sendSmsPersonalized;
      const payload = {
        clients: patients,
        content: isPersonalized ? { ...message } : { name: template.name, language: template.language },
        bussinesId: props.phoneId,
        type: isPersonalized ? "text" : "template",
        ...(isPersonalized ? {} : { 
          variables: Object.fromEntries(
              Object.entries(inputValues).filter(([_, value]) => 
                  !Array.isArray(value) || value.length > 0
              )
          ) 
      })
      
      };

      const { response } = await Gateways.WhatsApp.enviarMensaje(payload);
      const status = response?.[0]?.status;

      if (status === "rejected") {
        addToast("Error al enviar mensaje", { appearance: "error" });
      } else {
        addToast("Mensaje enviado correctamente.", { appearance: "success" });
        setMessage({});
      }
    } catch (error) {
      addToast(error.message, { appearance: "error" });
      throw new Error("Error send message templates: ", error);
    }
  };


  const mapperClientData = async (element) => {
    return Promise.all(
      element
        .filter(e => [9, 11].includes(e.telefono.length))
        .map(async (e) => {
          const empresaData = await getEmpresaById(e.empresaId);
          return {
            nombres: e.nombres,
            apellidos: e.apellidos,
            telefono: ensurePrefix(e.telefono),
            empresa: e.empresa,
            ...empresaData,
            ...mapImageVariableToTemplate()
          };
        })
    );
  };


  const mapImageVariableToTemplate = () => {
    const item = inputValues.header.find(item => item.type === 'image');
    if (!item || !item.field) return null;

    const variable = item.field.replace(/{{_|_}}/g, "");
    return { [variable]: template.header.example.header_handle[0] };
  };


  const ensurePrefix = (phoneNumber) => {
    const cleanedNumber = phoneNumber.replace(/\D/g, '');
    if (cleanedNumber.startsWith('51')) {
      return cleanedNumber;
    }
    return `51${cleanedNumber}`;
  }

  if (!props.isOpen) return null;

  return ReactDOM.createPortal(
    <ModalBody>
      <ModalContainer>
        <ModalHeaderContainer>
          <ModalHeaderTitle>{props.title}</ModalHeaderTitle>
          <ModalButton onClick={props.onClose}>
            <CloseIcon />
          </ModalButton>
        </ModalHeaderContainer>
        <ModalChildrenContainer>
          <ContainerBody>
            <Column size={4}>
              <div class="menu-template">
                <input type="text" class="mb-4 mt-2 form-control" />
                <div class="scrollable-list">
                  <div class="list-group">
                    <a
                      href="#"
                      class="list-group-item list-group-item-action active"
                      onClick={handleSendPersonalized}
                    >
                      Mensaje Personalizado
                    </a>
                    {listTemplates.map((template, index) => (
                      <a
                        href="#"
                        key={index}
                        onClick={() => handleSendTemplate(template)}
                        class="list-group-item list-group-item-action"
                      >
                        {template.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </Column>
            {!sendSmsPersonalized ? (
              <Column size={8}>
                <div class="template-content">
                  <div class="panel-header">
                    <input type="hidden" name="type" defaultValue="template" />
                    <div class="panel-title">
                      {" "}
                      <p>
                        <strong>
                          {template.name ? template.name.toUpperCase() : ""}
                        </strong>
                      </p>
                    </div>
                  </div>
                  <div class="send-content">
                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <strong>Contactos</strong>
                      </div>
                      <div class="panel-body">
                        <div
                          class="form-check form-check-inline"
                          onClick={() => {
                            setShowMultiSelect(false);
                          }}
                        >
                          <input
                            class="form-check-input"
                            checked={!showMultiSelect}
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios1"
                            value="option1"
                            checked
                          />
                          <label class="form-check-label" for="exampleRadios1">
                            Todos los contactos
                          </label>
                        </div>
                        <div
                          class="form-check form-check-inline"
                          onClick={() => {
                            setShowMultiSelect(true);
                          }}
                        >
                          <input
                            class="form-check-input"
                            checked={showMultiSelect}
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios2"
                            value="option2"
                          />
                          <label class="form-check-label" for="exampleRadios2">
                            Contactos individuales
                          </label>
                        </div>

                        {showMultiSelect && (
                          <MultiSelect
                            options={listClients}
                            onChange={handleSelectionChange}
                          />
                        )}
                      </div>
                    </div>
                    {template.header.format && (
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <strong>Encabezado</strong>
                        </div>
                        <div className="panel-body">
                          {template.header.format === "TEXT" ? (
                            <p>{template.header.text}</p>
                          ) : template.header.format === "IMAGE" ? (
                            <img
                              src={template.header.example.header_handle[0]}
                              alt="Example"
                              className="small-image"
                            />
                          ) : template.header.format === "FILE" ? (
                            <input
                              type="file"
                              accept="image/jpeg"
                              multiple
                            />
                          ) : null}
                        </div>

                      </div>
                    )}
                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <strong>Cuerpo</strong>
                      </div>
                      <div class="panel-body">
                        <p>{template.body.text}</p>
                        <div class="panel-parameters">
                          <div class="row">
                            {"example" in template.body &&
                              template.body.example.body_text[0].map(
                                (item, index) => {
                                  return (
                                    <div>
                                      <div class="cell flex">
                                        <input
                                          class="form form-control"
                                          value={inputValues?.body[index]?.field || ""}
                                          placeholder={`Escribe el contenido para {{${index + 1}}}`}
                                        ></input>
                                        <Dropdown
                                          variables={variables.body}
                                          name={"VARIABLES"}
                                          onSetVariable={(variable) =>
                                            handleSetVariable(index, variable, "body")
                                          }
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {template.footer.text != "" && (
                      <div class="panel panel-default">
                        <div class="panel-heading">
                          <strong>Pie de pagina</strong>
                        </div>
                        <div class="panel-body">
                          <p>{template.footer.text}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Column>
            ) : (
              <Column size={8}>
                <div class="template-content">
                  <input type="hidden" name="type" defaultValue="text" />
                  <div class="panel-header">
                    <div class="panel-title">
                      <p>
                        <strong>MENSAJE WHATSAPP </strong>
                      </p>
                    </div>
                  </div>
                  <div class="panel-body-send">
                    <div class="form-group">
                      <label
                        for="colFormLabelSm"
                        class="col-sm-3 col-form-label col-form-label-sm"
                      >
                        Idioma del mensaje <span class="mandatory">*</span>
                      </label>
                      <div class="col-sm-12">
                        <select
                          name="category"
                          class="form-select form-control"
                          aria-label="Default select example" disabled
                        >
                          <option value="es" selected disabled>Español</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group">
                      <label
                        for="colFormLabelSm"
                        class="col-sm-3 col-form-label col-form-label-sm"
                      >
                        Clientes <span class="mandatory">*</span>
                      </label>
                      <div class="col-sm-12">
                        <MultiSelect
                          options={listClients}
                          onChange={handleSelectionChange}
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label
                        for="colFormLabelSm"
                        class="col-sm-3 col-form-label col-form-label-sm"
                      >
                        Mensaje de Text <span class="mandatory">*</span>
                      </label>
                      <div class="col-sm-12">
                        <textarea type="text" name="message" value={message.message || ''} onChange={handleChange} class="form-control" maxlength="1024" cols="45" rows={4} id="basic-url"
                          aria-describedby="basic-addon3" />
                      </div>
                    </div>
                  </div>
                </div>
              </Column>
            )}
          </ContainerBody>
        </ModalChildrenContainer>
        <div class="modal-footer">
          <button class="btn btn-secondary" onClick={props.onClose}>
            Cancelar
          </button>
          <button class="btn btn-info" onClick={sendMessageWhatsapp}>
            Enviar Mensaje
          </button>
        </div>
      </ModalContainer>
    </ModalBody>,
    document.getElementById("modal")
  );
};
