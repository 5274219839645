const BASE_URL = "http://localhost:3005"

let headers = {
  "Content-Type": "application/json;charset=utf-8",
  Accept: "application/json",
};

async function callApi(endpoint, options = {}) {
  options.headers = headers
  const url = BASE_URL + endpoint;
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
}

const serialize = (object) => {
  var str = [];
  for (var p in object)
    if (object.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(object[p]));
    }
  return str.join("&");
}
const httpPostPromise = (url, payload, authorization = '') => {
  headers.Authorization = `Bearer ${authorization}`
  return new Promise((result, reject) => {
    fetch(url, { method: 'POST', headers, body: JSON.stringify(payload) })
      .then(r => r.json())
      .then(r => result(r))
      .catch(e => reject(e))
  })
}
const httpGetPromise = (url, payload, authorization = '') => {
 headers.Authorization = `Bearer ${authorization}`
  return new Promise((result, reject) => {
    let params = serialize(payload);
    fetch(`${url}?${params}`, { method: 'GET', headers })
      .then(r => r.json())
      .then(r => result(r))
      .catch(e => reject(e))
  })
}

const api = {
  pacientes: {
    getAll() {
      return callApi("/pacientes");
    },
    getPacienteById() { },
    createPaciente() { },
    updatePaciente() { },
  },
  especialistas: {
    getAll() {
      return callApi("/especialistas");
    },
    getPacienteById() { },
    createPaciente() { },
    updatePaciente() { },
  },
  recursos: {
    getAll() {
      return callApi("/recursos");
    },
  },
  prestaciones: {
    getAll() {
      return callApi("/prestaciones");
    },
  },
  WhatsApp: {
    enviarMensaje(payload) {
      return httpPostPromise(`${process.env.REACT_APP_API_URL}/api/v1/whatsappV2/send-message`, payload);
    },
    obtenerClientes(params) {
      return httpGetPromise(`${process.env.REACT_APP_API_URL}/api/v1/whatsappV2/get-clients`,params);
    }
  },
  Make: {
    getClientsByBussinesId(params) {
      return httpGetPromise(`https://hook.eu2.make.com/ir6vk0sbir3u1r3jv9g6onoqpy3f5iou`, params);
    }
  },
  MetaWhatsAppApi: {
    crearPlantilla(payload, businessID) {
      return httpPostPromise(`${process.env.REACT_APP_API_URL_META}/${businessID}/message_templates`, payload, `${process.env.REACT_APP_AUTHENTICATION_META}`);
    },
    getAllTemplatesByBusinessId(businessID){

      return httpGetPromise(`${process.env.REACT_APP_API_URL_META}/${businessID}/message_templates`,{},`${process.env.REACT_APP_AUTHENTICATION_META}`)
    },
    getAllBusinessRegistered(){
      return httpGetPromise(`${process.env.REACT_APP_API_URL}/api/v1/whatsappV2/all-phone-business-meta`,{}, {});
    }
  },

};
export default api;
